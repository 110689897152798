import { FC, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { useMap } from '../../../Hooks/Map/Map.hook';
import { MarkerType } from '../../../Redux/Slices/Map/Map.slice.types';
import { DynamicCenter } from './Controlls/DynamicCenter.com';
import L from 'leaflet';
import { ZoomLevelDisplay } from './Controlls/ZoomLevelDisplay.com';
import { Icon } from '../../Simple/Icon/Icon.com';
import { ZoomHandler } from './Controlls/ZoomHandler.com';
import { NearLavaa } from './Controlls/NearLavaa.com';
import { SearchFilters } from './Controlls/SearchFilters/SearchFilters.com';

// Marker Icon
const createSvgIcon = (color: string) => L.divIcon({
    html: `
        <svg stroke="${color}" fill="${color}" stroke-width="0" viewBox="0 0 384 512" height="32px" width="32px" xmlns="http://www.w3.org/2000/svg">
            <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"></path>
        </svg>
    `,
    className: '',
    iconSize: [32, 32],
    iconAnchor: [16, 32]
});

// Map Component
export const Map: FC = () => {

    // Hooks
    const location = useLocation();
    const navigate = useNavigate();
    const queryString = location.search;
    const { requestMapData, center, zoom, maxZoom, markers } = useMap();

    // States
    const [selectedMarker, setSelectedMarker] = useState<MarkerType | null>(null);
    const [details, setDetails] = useState<any | null>(null);
    const [sponsorSearch, setSponsorSearch] = useState<string>('');
    const [conditionsSearch, setConditionsSearch] = useState<string>('');

    // Handle Marker Click
    const handleMarkerClick = (marker: MarkerType) => {
        const { label } = marker;
        const queryParams = new URLSearchParams(location.search);

        // Cancel the selection
        if (selectedMarker?.label === label) {
            setSelectedMarker(null);
            queryParams.delete('selectedMarker');
            navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
            return;
        }

        // Set selected marker
        setSelectedMarker(marker);
        marker.details && setDetails(marker);
        
        // Update query params
        if (!label.startsWith('+')) {
            queryParams.set('selectedMarker', label);
            navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });   
        }
    };

    // Close details
    const closeDetails = () => {
        setSelectedMarker(null);
        setDetails(null);
    };

    // Get Marker Color
    const getMarkerColor = (marker: MarkerType, isSelected: boolean) => {
        if (isSelected) return 'red';
        if (marker.color === 'none') return 'blue';
        return marker.color;
    };

    // Handle Zoom Threshold Cross
    const handleZoomThresholdCross = (currentZoom: number) => {
        const queryParams = new URLSearchParams(location.search);
        // queryParams.set('zoom', currentZoom.toString());

        // Request map data
        // requestMapData(queryParams.toString());
        // navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    };

    // Handle Sponsor Search
    const onSponsorSearch = (value: string) => {
        setSponsorSearch(value);
    };

    // Handle Conditions Search
    const onConditionsSearch = (value: string) => {
        setConditionsSearch(value);
    };

    // Filter by Sponsor
    const filterBySponsor = (marker: MarkerType) => {
        if (!sponsorSearch) return true;
        return marker?.details && marker?.details?.Sponsor && marker.details.Sponsor.toLowerCase().includes(sponsorSearch.toLowerCase());
    };

    // Filter by Conditions
    const filterByCondition = (marker: MarkerType) => {
        if (!conditionsSearch) return true;
        return marker?.details && marker?.details?.Conditions && marker.details.Conditions.join(' ').toLowerCase().includes(conditionsSearch.toLowerCase());
    };

    useEffect(() => {
        requestMapData(queryString);
    }, [queryString]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const selectedMarkerLabel = queryParams.get('selectedMarker');
        if (selectedMarkerLabel) {
            const marker = markers.find(marker => marker.label === selectedMarkerLabel);
            setSelectedMarker(marker || null);
        }
    }, [location.search, markers]);

    return (
        <Col style={{width: '100%', height: '100%'}}>
            <MapContainer center={[center.lat, center.lng]} maxZoom={maxZoom} zoom={zoom} scrollWheelZoom={true} style={{height: '100%'}}>
                <ZoomHandler onZoomThresholdCross={handleZoomThresholdCross} />
                <ZoomLevelDisplay />
                <NearLavaa />
                <DynamicCenter center={center} zoom={zoom} />
                <SearchFilters onSponsorSearch={onSponsorSearch} onConditionsSearch={onConditionsSearch} />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    markers.filter(filterBySponsor).filter(filterByCondition).map((marker: MarkerType, index: number) => {
                        const isSelected = selectedMarker?.label === marker.label;
                        return (
                            <Marker 
                                key={`marker_${index}`} 
                                position={[marker.lat, marker.lng]}
                                icon={createSvgIcon(getMarkerColor(marker, isSelected))}
                                eventHandlers={{
                                    click: () => handleMarkerClick(marker)
                                }}
                            >
                                <Tooltip permanent={true}>{marker.label}</Tooltip>
                            </Marker>
                        )
                    })
                }

                {/* Popover for marker details */}
                {details ? (
                    <div style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: 6,
                        zIndex: 1000,
                        maxWidth: 300,
                        padding: '16px',
                        maxHeight: '50vh',
                        overflow: 'auto'
                    }}>
                        {/* Close Button */}
                        <div style={{position: 'absolute', top: '10px', right: '10px'}}>
                            <Icon name="close" size={16} onClick={closeDetails} />
                        </div>
                        <h3>Details</h3>
                        <p><strong>Label:</strong> {details.label}</p>
                        <p><strong>Coordinates:</strong> {details.lat}, {details.lng}</p>

                        {/* Details */}
                        {
                            typeof details.details === 'object' 
                            ? 
                            <ul style={{ padding: '0', listStyle: 'none' }}>
                                {
                                    Object.entries(details.details).map(([key, value]) => key === '_id' ? null : (
                                        <li key={key} style={{ marginBottom: '8px' }}>
                                            <strong>{key}:</strong>
                                            {
                                                key === 'Link' && value 
                                                ? 
                                                <Link to={value} target="_blank" rel="noreferrer" style={{
                                                    marginLeft: '0.5rem',
                                                    overflow: 'hidden',
                                                    maxWidth: '230px',
                                                    display: 'inline-block',
                                                    textOverflow: 'ellipsis',
                                                    verticalAlign: 'middle'
                                                }}>{String(value)}</Link>
                                                : 
                                                <span style={{marginLeft: '0.5rem'}}>
                                                    {Array.isArray(value) ? value.join(', ') : String(value)}
                                                </span>
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                            : 
                            <p style={{borderTop: '1px solid #808080', paddingTop: '8px'}}>
                                <strong>{details.details}</strong>
                            </p>
                        }
                    </div>
                ) : null}
            </MapContainer>
        </Col>
    )
};